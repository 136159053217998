<template>
  <div class="draft-music-review">
    <j-form model="drafts.draft" hide-errors>

      <!-- Artist -->
      <j-control
        name="title"
        label="Artist"
      />

      <!-- Title -->
      <j-control name="album" />

      <!-- Label -->
      <j-control name="label" />

      <!-- Review -->
      <j-control
        name="body"
        label="Review"
        :hint="reviewCharacters"
      />

      <!-- Sounds Like -->
      <j-control
        name="tags"
        label="Sounds Like"
      />

      <!-- Release Date -->
      <j-control name="release_date" />

      <!-- Playlist -->
      <j-control name="playlist" />
    </j-form>
  </div>
</template>

<script>

export default {
  name: 'DraftMusicReview',
  props: ['draft'],
  computed: {
    reviewCharacters () {
      return `(${this.draft.get('body').length} / 240)`
    }
  }
}

</script>
